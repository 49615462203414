import uri from 'urijs';
import { POST_LIST_SETTINGS_PARAMS } from '@wix/communities-blog-client-common/dist/src/constants/post-list-widget-constants';
import { getAppSettingsNumber, getAppSettingsValue } from '../../common/selectors/app-settings-base-selectors';
import { isExcludePostContentSupported } from '../../common/selectors/layout-selectors';
import { SECTION_POST_LIST } from '@wix/communities-blog-client-common/dist/src/constants/wix-params';
import { createPromisifiedAction } from '../../common/actions-promisifier/create-promisified-action';
import { buildPaginationRequestParams } from '../../common/services/pagination';
import setPosts from '../../common/actions/set-posts';
import { setIsLoading } from '../../common/store/is-loading/is-loading-actions';
import { getWixDataCategoryId } from '@wix/communities-blog-client-common/dist/src/store/wix-data/get-wix-data';

export const fetchPostListPosts = () => (dispatch, getState, { request }) => {
  const state = getState();
  const pageSize = getAppSettingsNumber(
    state,
    POST_LIST_SETTINGS_PARAMS.entityCount.wixParam,
    POST_LIST_SETTINGS_PARAMS.entityCount.defaultValue,
  );
  const featuredOnly = getAppSettingsValue({ state, key: POST_LIST_SETTINGS_PARAMS.isFeatured.appSettingsPath });
  const categoryId = getWixDataCategoryId(state);
  const params = buildPaginationRequestParams(1, pageSize);

  dispatch(setIsLoading('postListPosts', undefined, true));

  const promise = request(
    uri('/_api/posts').query({
      ...params,
      pinnedFirst: true,
      featuredOnly,
      excludeContent: isExcludePostContentSupported(state, SECTION_POST_LIST),
      categoryIds: categoryId ? [categoryId] : [],
    }),
  );

  return promise
    .then(posts => dispatch(setPosts(posts)))
    .then(() => dispatch(setIsLoading('postListPosts', undefined, false)))
    .catch(() => dispatch(setIsLoading('postListPosts', undefined, false)));
};

export const fetchPostListPostsPromisified = createPromisifiedAction(
  fetchPostListPosts,
  () => null,
  response => response.status,
);
