import { get, set } from 'lodash';
import { ITEM_TYPES } from '@wix/advanced-seo-utils/api';
import { SECTION_HOMEPAGE, SECTION_CATEGORY } from '@wix/communities-blog-client-common/dist/src/constants/wix-params';
import { formatTitle, getLastPage, createPageUrl } from './pagination';
import { getPaginationPageSize, getEntityCount } from '../selectors/pagination-selectors';
import {
  getFeedSEOTitle,
  getFeedSEOLinks,
  getFeedSEOMetaTags,
} from '../../feed-page/selectors/feed-seo-tags-selectors';
import { getImageUrl as _getImageUrl } from '@wix/communities-blog-universal/dist/src/utils/media/image';
import { getPostAmpUrl } from '../selectors/post-selectors';
import { getCategoryUrl } from './get-category-url';
import { getCanonicalPostUrl } from './post-utils';
import { getPostSeoDescription, getPostExcerpt } from '../../post-settings/services/post-utils-no-rce';
import { EXPERIMENT_TOGGLE_POST_INDEX_SEO_SETTINGS } from '@wix/communities-blog-experiments';
import { isExperimentEnabled } from '@wix/communities-blog-client-common/dist/src/selectors/experiments-selectors';
import { getPostCover } from './get-post-cover';
import { getSchemaEnabled } from '../selectors/app-data-selectors';
import { getCategoriesMap } from '../selectors/categories-selectors';
import { getTopology } from '../store/topology/topology-selectors';

const getImageUrl = (appConfig, image, maxWidth, maxHeight, quality, type, format, blur) =>
  _getImageUrl(appConfig.imageHost)(image, { maxWidth, maxHeight, quality, type, format, blur });

export const generateFeedSEOTags = ({ showPagination, state, page = 1, t }) => {
  const { baseUrl, sectionUrl, isHomePage } = getTopology(state);
  const canonicalUrl = page === 1 ? (isHomePage ? baseUrl : sectionUrl) : createPageUrl(page, sectionUrl);
  let title = getFeedSEOTitle(state);
  const links = getFeedSEOLinks(state);
  const metaTags = getFeedSEOMetaTags(state);

  !links.find(item => item.rel === 'canonical') && links.push({ rel: 'canonical', href: canonicalUrl });
  !metaTags.find(item => item.property === 'og:url') && metaTags.push({ property: 'og:url', content: canonicalUrl });

  if (showPagination) {
    const pageSize = getPaginationPageSize(state, SECTION_HOMEPAGE);
    const entityCount = getEntityCount(state, 'posts');
    const lastPage = getLastPage(entityCount, pageSize);
    title = formatTitle({ title, page, lastPage, t });

    if (page > 1) {
      links.push({ rel: 'prev', href: createPageUrl(page - 1, sectionUrl) });
    }

    if (page < lastPage) {
      links.push({ rel: 'next', href: createPageUrl(page + 1, sectionUrl) });
    }
  }

  return {
    title,
    links,
    metaTags,
  };
};

export const generateCategorySEOTags = ({ appConfig, category, showPagination, state, page, t }) => {
  const { sectionUrl, categoryPath } = appConfig;
  const categoryUrl = getCategoryUrl(sectionUrl, categoryPath, category.slug);

  const itemData = {
    title: appConfig.useCategoryMenuLabelForMetadataTitle ? category.menuLabel : category.label,
    description: category.description,
    canonicalUrl: createPageUrl(page, categoryUrl),
  };

  if (showPagination) {
    const pageSize = getPaginationPageSize(state, SECTION_CATEGORY);
    const entityCount = getEntityCount(state, 'posts');
    const lastPage = getLastPage(entityCount, pageSize);
    itemData.title = formatTitle({ title: itemData.title, page, lastPage, t });

    if (page > 1) {
      itemData.prevUrl = createPageUrl(page - 1, categoryUrl);
    }

    if (page < lastPage) {
      itemData.nextUrl = createPageUrl(page + 1, categoryUrl);
    }
  }

  if (category.cover) {
    const { width, height } = category.cover;
    itemData.image = {
      url: getImageUrl(appConfig, category.cover),
      width,
      height,
    };
  }

  return {
    itemType: ITEM_TYPES.BLOG_CATEGORY,
    itemData: { category: itemData },
  };
};

const getPostFlattenedCategories = (post, categoryMap) =>
  post.categoryIds
    .map(id => categoryMap[id])
    .filter(Boolean)
    .map(({ label }) => label)
    .join(', ');

export const generatePostPageSEOTags = ({ appConfig, post, state }) => {
  const { postPageSectionUrl, ampBaseUrl, publisher } = appConfig;
  const { slug, seoDescription, title, seoTitle, lastPublishedDate, firstPublishedDate, owner } = post;

  const itemData = {
    post: {
      title,
      description: getPostSeoDescription(post),
      amphtml: getPostAmpUrl(state, post._id, ampBaseUrl),
      type: 'article',
      canonicalUrl: getCanonicalPostUrl({ post, postPageSectionUrl }),
      optInMeta: 'max-snippet:-1, max-image-preview:large, max-video-preview:-1',
      slug,
      excerpt: getPostExcerpt(post),
      lastPublishedDate,
      firstPublishedDate,
      categories: getPostFlattenedCategories(post, getCategoriesMap(state)),
      owner: {
        name: owner.name,
      },
    },
    legacySeoData: {
      title: seoTitle,
      description: seoDescription,
    },
    postsSettings: {
      isAmpEnabled: Boolean(ampBaseUrl),
      isSchemaEnabled: Boolean(appConfig.isWP || getSchemaEnabled(state)),
    },
  };

  if (owner.image) {
    set(
      itemData,
      'post.owner.image.url',
      typeof owner.image === 'string' ? owner.image : getImageUrl(appConfig, owner.image),
    );
  }

  if (publisher) {
    itemData.publisher = {
      id: publisher['@id'],
      name: publisher.name,
      logo: get(publisher, 'logo.url'),
    };
  }

  const isTogglePostIndexSEOExperimentEnabled = isExperimentEnabled(EXPERIMENT_TOGGLE_POST_INDEX_SEO_SETTINGS);
  const isPostShownInSearch = get(post, 'seoShowInSearch', true);
  const isPostSnippetShownInSearch = get(post, 'seoShowSnippetInSearch', true);
  if (isTogglePostIndexSEOExperimentEnabled) {
    if (!isPostShownInSearch) {
      itemData.post.noIndex = true;
    }

    if (!isPostSnippetShownInSearch) {
      itemData.post.noSnippet = true;
    }
  }

  const { shouldRender, imageMetadata, videoMetadata } = getPostCover(post);
  if (shouldRender) {
    itemData.post.image = {
      url: imageMetadata ? getImageUrl(appConfig, imageMetadata) : videoMetadata.thumbnail_url,
      width: imageMetadata ? imageMetadata.width : videoMetadata.thumbnail_width,
      height: imageMetadata ? imageMetadata.height : videoMetadata.thumbnail_height,
    };
  }

  return {
    itemType: ITEM_TYPES.BLOG_POST,
    itemData,
  };
};
